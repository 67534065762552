body {
  line-height: 1.5;
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;

  &.has-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;

    &.is-homepage {
      &::before {
        content: unset;
      }
    }

    &.is-dark {
      color: #fff;

      &::before {
        opacity: 0.25;
        background-color: #000;
      }

      &::after {
        color: #fff;
      }
    }

    &::before {
      content: "";
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-color: #fff;
      opacity: 0.5;
    }

    &::after {
      content: attr(data-image-credit);
      z-index: 0;
      opacity: 1;
      display: block;
      padding: 0 1rem;
      opacity: .5;
      position: absolute;
      bottom: 0.75em;
      left: 0;
      right: 0;
      z-index: 0;
      font-size: .5em;

      @media screen and (min-width: 48em) {
        text-align: right;
        position: fixed;
      }
    }
  }
}

.intro {
  font-size: 1.75em;
}

h1 {
  font-size: 2.67em;
  padding: 0;
  margin: 0;
}

a {
  color: #c41f46;
  text-decoration: none;
}

.site-title {
  display: block;
  color: #000;
  opacity: unset;
  font-size: 1em;
  font-weight: 700;
}

.is-homepage {
  .site-header {
    flex-direction: column;
  }

  .site-title {
    padding: 1rem 0;

    @media screen and (min-width: 48em) {
      font-size: 3em;
    }
  }

  nav {
    margin-left: 0;

    li {
      display: list-item;
      margin-top: 1.5em;

      &::before {
        content: unset;
      }
    }

    a {
      @media screen and (min-width: 48em) {
        font-size: 3em;
      }
    }
  }
}

.is-dark {
  .site-title {
    color: #fff;
  }

  nav {
    a {
      color: #fff;
    }
  }
}

nav {
  @media screen and (min-width: 48em) {
    margin-left: 1em;
  }

  ul {
    position: relative;

    ul {
      border-top: 1px solid #e6e6e6;
      position: absolute;
      left: 0;
      right: 0;

      a {
        text-transform: unset;
      }
    }
  }

  .event {
    a {
      opacity: 1;
    }
  }

  li {
    display: inline-block;

    &:hover {
      a {
        opacity: 1;
      }

      &::before {
        opacity: 0.5;
      }
    }

    &::before {
      content: "\2022";
      opacity: 0.5;
    }

    &:first-child::before {
      content: unset;
    }
  }

  a {
    color: #000;
    font-size: 1em;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    transition-property: opacity;
    transition-duration: 200ms;
    opacity: 0.5;
  }

  .is-ancestor,
  .is-current {
    > a {
      opacity: 1;
    }
  }

  .has-children {
    padding-bottom: 1.5em;
  }

  .events {
    margin: 0.75em 0;

    a {
      font-size: 1em;
    }
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .site-header {
    flex: 0;
  }

  .page-content {
    flex: 1;
  }

  .site-footer {
    flex: 0;
    padding: 1.5em 1em 1em;

    @media screen and (min-width: 48em) {
      padding: 1.5em 3em 1em;
    }
  }
}

.site-header {
  display: flex;
  padding: 1em;
  flex-direction: column;

  @media screen and (min-width: 48em) {
    flex-direction: row;
    padding: 1em 3em;
  }
}

.site-info {
  a {
    &::after {
      content: " | "
    }

    &:last-child::after {
      content: unset;
    }
  }
}

.page-content {
  padding: 0 1em;
  max-width: 51em;
  width: 100%;

  @media screen and (min-width: 48em) {
    padding: 0 3em;
  }

  h1 {
    margin: 0.5em 0 0.25em 0;
    font-size: 1.75em;
    text-transform: uppercase;

    @media screen and (min-width: 48em) {
      font-size: 2.67em;
    }
  }

  h2 {
    font-size: 1.25em;
    @media screen and (min-width: 48em) {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 0.75em;
    @media screen and (min-width: 48em) {
      font-size: 1em;
    }
  }
}

.list--clean {
  margin: 0;
  padding: 0;
  list-style: none;
}

.messengers {
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  @media screen and (min-width: 48em) {
    max-width: 64em;
  }

  .messenger {
    display: flex;
    margin-bottom: 1.5em;
    flex-direction: column;
    @media screen and (min-width: 48em) {
      flex-direction: row;
    }

    .messenger-thumbnail {
      @media screen and (min-width: 48em) {
        margin-right: 1em;
      }

      img {
        display: block;
      }

      &__thumb.has-credit {
        position: relative;

        &::after {
          text-align: right;
          content: attr(data-image-credit);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 0;
          font-size: .5em;
          padding: 0.25em 0.75em;
          opacity: 0.25;
          background-color: #000;
          color: #fff;
        }
      }
    }

    .messenger-details__name {
      font-size: 2em;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
    }

    .messenger-details__title {
      font-weight: 700;
      margin-top: 0;
    }
  }
}
